import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import { lighten } from "@theme-ui/color"

const MenuSocial = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query MenuSocial{
        dataJson(slug: {eq: "menu-social"}) {
          fragment_1
          fragment_2
          fragment_3
        }
      }
    `
  )

  const dataFragments = data.dataJson
  const isFrontPage = ( props.page === 'frontpage' ) ? true : false

  return(
    <Box
      as='div'
      className="menu-social"
      sx={{
        ul: {
          margin: 0,
          padding: 0,
        },
        li: {
          listStyle: `none`,
          margin: 0,
          "+ li": {
            marginLeft: 1,
          }
        },
        p: {
          margin: 0,
        },
        svg: {
          fill: lighten(`primary`, 0.4),
          borderBottom: `none`,
          height: 5,
          verticalAlign: `middle`,
          textDecoration: `none`,
          ":hover": {
            fill: `tertiary`,
          },
        },
        ".content-menu": {
          alignItems: `center`,
          borderTop: theme => `1px solid ${theme.colors.background}`,
          justifyContent: `space-between`,
          paddingTop: 2,
          mt: 2,
          p: {
            color: lighten(`primary`, 0.2),
            fontSize: 0,
          },
          ".link-to-version": {
            textTransform: `uppercase`,
          },
          a: {
            color: lighten(`primary`, 0.4),
            borderBottom: `none`,
            ":hover": {
              fill: `tertiary`,
              borderBottom: `none`,
            },
          },
        }
      }}
    >
      <Flex
        className="content-menu"
      >
        {isFrontPage === true
          ? <Themed.p className="link-to-version"><Themed.a href={dataFragments.fragment_3} rel="nofollow" hreflang="ca" lang="ca">{dataFragments.fragment_1}</Themed.a></Themed.p>
          : <Themed.p>{dataFragments.fragment_2}</Themed.p>
        }
        <Box
          as="nav"
          aria-label="Navigation"
        >
          <Flex
            as="ul"
            className="menu-items"
          >
            <li>
              <a href="https://www.facebook.com/Entrepbs" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" aria-label="Link to Facebook"><title>facebook</title><path d="M20 0.8c-10.604 0-19.2 8.596-19.2 19.2s8.596 19.2 19.2 19.2 19.2-8.596 19.2-19.2-8.596-19.2-19.2-19.2zM24.548 14.068h-2.886c-0.342 0-0.722 0.45-0.722 1.048v2.084h3.61l-0.546 2.972h-3.064v8.922h-3.406v-8.922h-3.090v-2.972h3.090v-1.748c0-2.508 1.74-4.546 4.128-4.546h2.886v3.162z"></path></svg></a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UC9HjJaFL016TWAA8zClLbtg" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" aria-label="Link to Youtube"> <title>youtube</title> <path d="M23.206 19.666l-4.492-2.096c-0.392-0.182-0.714 0.022-0.714 0.456v3.948c0 0.434 0.322 0.638 0.714 0.456l4.49-2.096c0.394-0.184 0.394-0.484 0.002-0.668zM20 0.8c-10.604 0-19.2 8.596-19.2 19.2s8.596 19.2 19.2 19.2 19.2-8.596 19.2-19.2-8.596-19.2-19.2-19.2zM20 27.8c-9.828 0-10-0.886-10-7.8s0.172-7.8 10-7.8 10 0.886 10 7.8-0.172 7.8-10 7.8z"></path> </svg></a>
            </li>
            <li>
              <a href="https://vimeo.com/entrepbs" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" aria-label="Link to Vimeo"> <title>vimeo</title> <path d="M20 0.8c-10.604 0-19.2 8.596-19.2 19.2s8.596 19.2 19.2 19.2 19.2-8.596 19.2-19.2-8.596-19.2-19.2-19.2zM28.802 16.3c-1.016 5.832-6.696 10.774-8.402 11.902-1.708 1.124-3.268-0.454-3.832-1.648-0.648-1.364-2.586-8.746-3.094-9.36-0.508-0.612-2.032 0.614-2.032 0.614l-0.738-0.988c0 0 3.094-3.766 5.448-4.234 2.496-0.5 2.492 3.902 3.092 6.348 0.582 2.366 0.972 3.718 1.478 3.718 0.508 0 1.478-1.316 2.538-3.34 1.064-2.024-0.044-3.812-2.122-2.54 0.83-5.080 8.68-6.304 7.664-0.472z"></path> </svg></a>
            </li>
            <li>
              <a href="https://www.instagram.com/entrepbs/" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" aria-label="Link to Instagram"> <title>instagram</title> <path d="M26 20c0 3.314-2.686 6-6 6s-6-2.686-6-6c0-0.342 0.036-0.676 0.098-1h-2.098v7.994c0 0.556 0.45 1.006 1.006 1.006h13.99c0.554 0 1.004-0.45 1.004-1.006v-7.994h-2.098c0.062 0.324 0.098 0.658 0.098 1zM20 24c2.208 0 4-1.792 4-4s-1.792-4-4-4-4 1.792-4 4 1.792 4 4 4zM24.8 15.8h2.398c0.332 0 0.602-0.27 0.602-0.6v-2.398c0-0.332-0.27-0.602-0.602-0.602h-2.398c-0.332 0-0.602 0.27-0.602 0.602v2.398c0.002 0.33 0.272 0.6 0.602 0.6zM20 0.8c-10.604 0-19.2 8.596-19.2 19.2s8.596 19.2 19.2 19.2c10.604 0 19.2-8.596 19.2-19.2s-8.596-19.2-19.2-19.2zM30 27.778c0 1.222-1 2.222-2.222 2.222h-15.556c-1.222 0-2.222-1-2.222-2.222v-15.556c0-1.222 1-2.222 2.222-2.222h15.556c1.222 0 2.222 1 2.222 2.222v15.556z"></path> </svg></a>
            </li>
            <li>
              <a href="https://twitter.com/EntrepbsHarte" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -5 40 40" aria-label="Link to twitter"> <title>twitter</title><path d="M24.325 3h4.411l-9.636 11.013 11.336 14.987h-8.876l-6.952-9.089-7.955 9.089h-4.413l10.307-11.78-10.875-14.22h9.101l6.284 8.308zM22.777 26.36h2.444l-15.776-20.859h-2.623z"></path></svg></a>
            </li>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default MenuSocial